<template>
  <b-container fluid>
   <insight-detail></insight-detail>
    <b-row class="m-0">
      <b-col class="col-md-2 col-12">
        <b-form-group
          label="Sort By"
          label-for="sort-by-select"
          v-slot="{ ariaDescribedby }"
        >
          <div class="d-flex align-items-center">
            <b-form-select
              id="sort-by-select"
              v-model="sortBy"
              :options="sortOptions"
              :aria-describedby="ariaDescribedby"
              class="border mr-2 w-60 custom-font-22"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              :disabled="!sortBy"
              :aria-describedby="ariaDescribedby"
              @input="filterTrxn"
              class="border w-40 custom-font-22"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </div>
        </b-form-group>
      </b-col>

      <b-col class="col-md-2 col-12">
        <b-form-group label="Filter by Country">
          <multiselect
            :options="countryOpt"
            label="name"
            track-by="name"
            v-model="selectedCountry"
            placeholder="Select Country"
            @input="changeNetworkType"
            @remove="removeNetworkType"
          >
          </multiselect>
        </b-form-group>
      </b-col>
      <b-col class="col-md-2 col-12">
        <b-form-group label="Filter by Network">
          <multiselect
            :options="networkTypeArray"
            label="name"
            track-by="id"
            v-model="selectedNetworkType"
            placeholder="Select Network"
          >
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col
        class="col-md-6 col-12 text-right d-flex justify-content-end align-items-center"
      >
        <button
          class="btn custom-btn"
          style="margin-top: 0.7vh"
          @click="filterTrxn()"
        >
          Filter
        </button>
        <button
          class="btn custom-btn ml-2"
          style="margin-top: 0.7vh"
          @click="init()"
        >
          Reset
        </button>
      </b-col>
    </b-row>
   
    <b-row class="mt-2">
      <b-col xl="12" md="12">
        <b-card title="" type="gradient-red" sub-title="">
          <b-row>
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Insight Service Tests</span>
            </b-col>
            <b-col class="text-right col-md-6 col-12 btn-container">
              <button class="btn mb-2 custom-btn" @click="addTrx()">
                Add Service Test
              </button>
            </b-col>
          </b-row>
          <b-row class="mb-3">
            <b-col class="col-md-5 col-12 badge-container">
              <span class="badge badge-success p-2 custom-font-20"
                >Approved
                <span class="ml-1 custom-font-24">{{ counter.approved }}</span>
              </span>
              <span class="badge badge-gray p-2 custom-font-20"
                >Pending
                <span class="ml-1 custom-font-24">{{ counter.pending }}</span>
              </span>
              <span class="badge badge-danger p-2 custom-font-20"
                >Rejected
                <span class="ml-1 custom-font-24">{{ counter.rejected }}</span>
              </span>
            </b-col>

            <b-col class="col-md-3 col-12 mt-2">
              <div>
                <div slot="with-padding" class="row">
                  <div></div>
                </div>
              </div>
            </b-col>

            <b-col class="text-right col-md-4 col-12">
              <b-form-group
                label-cols="9"
                label="Per page"
                class="mb-0 custom-font-18"
              >
                <b-form-select
                  class="col-md-12 border custom-font-24"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="filterTrxn()"
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>

          <!-- Table starts -->
          <b-row class="table-responsive datatable-vue">
            <b-table
              class="text-left custom-font-16 custom-table-style"
              :fields="trxFields"
              striped
              hover
              :items="insightList"
              :busy="isBusy"
              stacked="md"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              responsive
            >
              <template #cell(_id)="row">
                <b
                  class="text-blue cust_text"
                  style="cursor: pointer"
                  @click.prevent="openTrxDetail(row.item)"
                  >{{ row.item._id }}
                </b>
              </template>
              <template #cell(homeimage)="row">
                <img
                  :src="getImageLanding(row.item._id)"
                  alt="landing"
                  class="img_custom_style"
                  @click="openImageModal(getImageLanding(row.item._id))"
                  @error="$event.target.src = `../no_image.png`"
                />
              </template>
              <template #cell(sid)="row">
                <span v-if="serviceList.find((e) => e._id == row.item.sid)">
                  {{ serviceList.find((e) => e._id == row.item.sid).title }}
                </span>
              </template>

              <template #cell(netType)="row">
                
                <span>
                  {{networks[row.item.netType]}}
                </span>
              </template>

              <!-- <template #cell(status)="row">
                  <div class="media-body icon-state switch-outline">
                        <label class="switch">
                        <input type="checkbox" :checked=row.item.status @change="changeStatus(row.item)">
                        <span class="switch-state" :class="row.item.status?'bg-success':'bg-danger'"></span>
                        </label>
                  </div>
              </template> -->
              <template #cell(country)="row">
                <!-- {{ row.item.country }} -->
                {{row.value}}
              </template>
              <template #cell(status)="row">
                <div class="media-body icon-state switch-outline">
                 <div class="col">
									<div class="form-group m-t-15 custom-radio-ml">
                    <div class="radio radio-primary">
											<b-form-radio :name="row.item._id" v-model="row.value" value=1 @change="changeStatus(row, 1)">Pending</b-form-radio>
										</div>
										<div class="radio radio-success">
                      
											<b-form-radio :name="row.item._id" v-model="row.value" value=2 @change="changeStatus(row, 2)">Approve</b-form-radio>
										</div>
										<div class="radio radio-secondary">
											<b-form-radio :name="row.item._id" v-model="row.value" value=3 @change="changeStatus(row, 3)">Reject</b-form-radio>
										</div>
									</div>
								</div>

                </div>
              </template>
              <template #cell(actions)="row">
                <button
                  size="sm"
                  @click="openTrxDetail(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteTrxDetail(row.item)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
              <template #table-busy>
                <div class="col-md-12" >
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div> 
              </template>
            </b-table>
          </b-row>
          <b-col class="mt-3 p-0 text-right">
            <div class="custom-pagination">
              <a v-show="currentPage != 1" @click="filterTrxn('prev')"
                >&laquo; Back</a
              >
              <a class="active">{{ currentPage }}</a>
              <a v-show="trxData.length >= 1" @click="filterTrxn('next')"
                >Next &raquo;</a
              >
            </div>
          </b-col>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import InsightService from "../../../../services/InsightService";
import NetworkTypeService from "../../../../services/NetworkTypeService";
import ServiceInfo from "../../../../services/ServiceInfo";
import Multiselect from "vue-multiselect";
import config from './../../../../services/globalLit'
import InsightDetail from "./InsightDetails.vue";

export default {
  components: {
    Multiselect,
    InsightDetail,
  },
  name: "insightlistmanager",
  props: {},
  data() {
    return {
      
      sortDirection: "desc",
      serviceList: [],
      counter: {
        approved: 0,
        pending: 0,
        rejected: 0,
      },
      perPage: 50,
      pageOptions: [50, 100, 200],
      trxFields: [
        { key: "_id", label: "ID", sortable: true },
        {
          key: "homeimage",
          label: "Home Image",
          sortable: true,
        },
        {
          key: "sid",
          label: "Service",
        },

        {
          key: "netType",
          label: "Network Type",
        },
        {
          key: "country",
          label: "Country",
          sortable: true,
        },
        {
          key: "status",
          label: "Approval",
          sortable: true,
        },
        {
          key: "createdAt",
          label: "Time",
          sortable: true,
          formatter: (value) => {
            // Options to format the date in DD/MM/YYYY HH:mm format
            const options = {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            };
            return new Date(value).toLocaleString("en-IN", options);
          },
          class: "cust-table-field",
        },
        {
          key: "actions",
          label: "Action",
        },
      ],
      trxData: [],
      currentPage: 1,
      isBusy: false,
      sortBy: "",
      sortDesc: false,
      sortOptions: [
        { value: "createdAt", text: "Date" },
        { value: "netType", text: "Network Type" },
        { value: "country", text: "Country" },
      ],
      countryOpt: [],
      selectedCountry: [],
      networkTypeArray: [],
      selectedNetworkType: [],

      fields: [
        { key: "_id", label: "ID" },
        { key: "netType", label: "Network" },
        { key: "createdAt", label: "Date" },
      ],
      defaultPayload: {},
      insightList: [],
      networks: [],
    };
  },
  mounted() {
    this.$root.$on("insightlist", () => {
      this.init();
    });
    this.init();
    
  },
  methods: {
    async changeStatus(data, value) {
      let payload = {
        insight_id: data.item._id,
        updateObj: {
          status: value,
        },
      };
      try {
        let response = await InsightService.update(payload);
        if (response.result) {
          this.init();
          this.$toasted.show("status changed!", {
            theme: "outline",
            position: "bottom-center",
            type: "success",
            duration: 2000,
          });
        } else {
          this.$toasted.show(response.message, {
            theme: "outline",
            position: "bottom-center",
            type: "error",
            duration: 2000,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
   async deleteTrxDetail(data) {
      let payload = {
        insight_id: data._id,
      };
      if (confirm("Are you sure you want to delete")) {
        let response = await InsightService.remove(payload);
        this.init();
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
      }
    },
    async openTrxDetail(data) {
        this.$root.$emit("insightdetail", Object.assign(data, { isEdit: true }));
        this.$bvModal.show("openInsightDetail");
    },
     addTrx() {
      this.$root.$emit("insightdetail", { isEdit: false });
      this.$bvModal.show("openInsightDetail");
    },
    openImageModal(data) {
      // window.open('', '_blank', `width=${screen.width},height=${screen.height}`).document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      const popupWindow = window.open('', '_blank', `width=${screen.width},height=${screen.height}`);
      popupWindow.document.write(`<html><head><title>Image Popup</title></head><body><img src="${data}" style="max-width: 100%; height: auto;"></body></html>`);
      popupWindow.document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        popupWindow.close();
      }
    });
    },
    getImageLanding(id) {
      return config.getInsightTestHome + `/${id}`;
    },
   async changeNetworkType() {
      console.log(this.selectedCountry);
      try {
        await this.getNetworkType(this.selectedCountry.id);
      } catch (error) {
        console.log("error in getting net type");
        console.log(error);
      }
    },
     async removeNetworkType() {
      this.selectedCountry = [];
      await this.changeNetworkType();
    },
    async getNetworkType(country) {
      let response = await NetworkTypeService.getAllNetworkType(country);
      let networkTypeArray = [];
      for (const [key, value] of Object.entries(response.data)) {
        networkTypeArray.push({ id: key, name: value });
      }
      this.networkTypeArray = networkTypeArray;
    },
    async getCountry() {
      let response = await NetworkTypeService.getCountry();
      this.countryOpt = [];
      response.data.forEach((element) => {
        this.countryOpt.push({ id: element.alpha_3, name: element.name });
      });
    },
    async filterTrxn() {
     
      let payload = {
        page: this.currentPage,
        perPage: this.perPage,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        country: this.selectedCountry.id,
        netType: this.selectedNetworkType.id,
      };
      
      await Promise.all([
        this.getList(payload),
        this.getInsightCount(payload),
      ]);
   
    
    },

    async getServiceList() {
        let response = await ServiceInfo.get();
        if (response.result) {
            this.serviceList = response.data;
        }
    },
    async getInsightCount(option = {}) {
      let payload = {...this.defaultPayload};
      payload = {...payload, ...option};
      
      let response = await InsightService.validateCounter(payload);
      if (response.result) {
        this.counter = response.data;
      }
    },
    init() {
      this.getInsightCount()
      this.getList();
      this.getNetworks();
      this.getServiceList();
      this.getCountry();
    },
    async getNetworks() {
     
      let response = await NetworkTypeService.getAllNetworkType();
      if (response.result) {
        this.networks = response.data;
      }
    },
    async getList(options = {}) {
       this.isBusy = true;

      let payload = this.defaultPayload;
      payload = { ...payload, ...options };
      let response = await InsightService.getInsightList(payload);
      // console.log(response);
      if (response.result) {
        this.insightList = response.data;
      }

       this.isBusy = false;
    },
  },
};
</script>
<style scoped>
.img_custom_style {
  min-height: 25px;
  min-width: 15px;
  max-height: 150px;
  max-width: 100px;
  border-radius: 10px 10px 10px 10px;
}
</style>